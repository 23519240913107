.searchContainer {
  min-width: 200px;
  min-height: 42px;
}

.select {
  margin-left: -10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 75px;
}

.pin {
  display: none;
}
