.columnflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
     gap: 10px; /* Add the gap property to specify the gap between flex items */
     margin-top: 20px;
     margin-bottom: 40px;
   }
   .autocomplete-tooltip-container {
    display: flex;
    align-items: center;
  }
  
  .info-icon {
    margin-left: 8px; /* Adjust as needed */
  }
  .array{
    margin-top: 20px;
    
  }
