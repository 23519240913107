/* MatchCircle.css */
.matchCircle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
  }
  
  .matchRing {
    
  }
  
  .matchRingCircle,
  .matchRingMatch {
    fill: none;
    stroke-width: 7; /* Adjust this to control the thickness of the ring */
  }
  
  .matchRingMatch {
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s;
  }
  
  .matchText {
    font-size: 17px; /* Adjust the font size as needed */
    fill: #fff;
    font-weight: bold;
  }
  