.containerDaterangePicker {
    background-color: #f2f3f4;
    margin-top: -15px;
    border-radius: 5px !important; 
    margin-right: 140px;
    
   }

   .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{    height: 0.3375em !important;

    width: 300px !important;
}

@media screen and (max-width: 900px) {
    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
        width: 290px !important;     }
        .containerDaterangePicker{
            margin-right: 0px !important;
        }
}
 

 