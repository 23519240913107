.BaseTable {
  margin: 0;
  box-shadow: none !important;
  background-color: var(--background-primary) !important;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: 100% !important;

  &__body {
    scrollbar-color: var(--accent-primary) var(--background-secondary) !important;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-track {
      background: var(--background-secondary);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--accent-primary);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--accent-secondary);
    }

    &::-webkit-scrollbar-corner {
      background: var(--background-tertiary);
    }
  }

  &__table-main {
    background-color: var(--background-primary) !important;
    outline: none;
    height: 100%;

    .BaseTable__header {
      min-height: var(--min-header-height);
    }
  }

  &__row {
    background-color: var(--background-secondary) !important;
    margin-bottom: 2px;

    &:hover {
      &>div {
        background-color: var(--background-tertiary) !important;
      }
    }

    &-cell {
      color: var(--text-primary);
      background-color: var(--background-secondary);
      cursor: pointer;
      justify-content: center;

      &:last-child {
        padding-right: 0 !important;
      }
    }

    &--frozen {
      background-color: var(--background-tertiary);
      margin-bottom: 0 !important;

      & #pin-icon {
        display: block !important;
      }

      &-left {
        box-shadow: none !important;
      }
    }

    &--customized {
      background-color: var(--background-secondary);
      border-radius: 10px;
      border: 3px solid var(--background-primary) !important;
    }
  }

  &__header {
    background-color: var(--background-primary) !important;
    margin-bottom: 2px;
    overflow: hidden;

    &-row {
      background-color: var(--background-secondary) !important;
      outline: none;
      border-radius: 10px;
      border: 0;
      border-color: var(--background-primary) !important;
    }

    &-cell {
      background-color: var(--background-secondary) !important;
      color: var(--text-primary);
      overflow: unset !important;
      justify-content: center;

      &:hover {
        background-color: var(--background-tertiary) !important;
      }
    }
  }
}

.row-selected {
  background-color: var(--background-tertiary);
}

@media screen and (max-width: 750px) {
  .panel {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 70%;
    background: var(--background-secondary);
    border: 4px solid var(--background-tertiary);
    margin: auto;
  }

  .containerInUploadComponent {
    color: var(--text-secondary);
    border: none;
  }
}

@media screen and (min-width: 750px) {
  .panel {
    display: flex;
    flex-direction: column;
    width: 64%;
    height: 431px;
    background: var(--background-secondary);
    border: 4px solid var(--background-tertiary);
    margin: auto;
  }

  .containerInUploadComponent {
    color: var(--text-secondary);
    border: 3px dashed var(--text-secondary);
    border-radius: 25px;
    height: 100%;
  }

  .containerInUploadComponentV2 {
    color: var(--text-secondary);
  }
}