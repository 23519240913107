.componentUploadFile {
  border: none;
  min-width: 17rem;
  height: 87%;
  margin-top: 3%;
}
.uploadFileContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}
/* top component -- span the whole non-menu space */
.pagePanelUploadFile {
  display: flex;
  height: 100% !important;
  width: 100% !important;
}

/* */
.uploadData_UploadContainer {
  display: flex;
  justify-content: space-evenly;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  position: relative;
  margin: auto;
  align-items: center;
}

.parentContent {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 90%;
  align-items: center;
}
.iconUploadFolder {
  text-align: center;
}
.iconUploadFolder > svg {
  font-size: 4.5rem !important;
}
.parentContent > button {
  cursor: pointer;
  width: 69%;
  margin-left: auto;
  margin-right: auto;
  height: 50px;
  border: none;
  color: white;
  font-size: 21px;
  font-weight: bold !important;
  background: #000129;
}
@media screen and (min-width: 800px) {
  .textUpload {
    display: flex;
    min-height: 50px;
    width: 320px;
    align-items: center;
    justify-items: center;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;
    text-align: center;
  }
  .uploadNavigationContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .uploadNavigationGroup {
    width: 100%;
    height: 50px;
    max-height: 80px;
    display: flex;
    flex-direction: row;
    padding: 0;
    justify-content: space-evenly;
  }
  .navigationIcons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .text-clickable {
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    font-weight: bold;
  }
}

.buttonUpload {
  width: calc(100% - 20px);
  font-size: 16px;
  margin: 10px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
}

.uploadNavigationContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.uploadNavigationGroup {
  width: 100%;
  height: 50px;
  max-height: 80px;
  display: flex;
  flex-direction: row;
  padding: 0;
  justify-content: space-evenly;
}

.navigationIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .text-clickable {
    background: none;
    border: none;
    font-size: 10px;
    cursor: pointer;
  }
  .uploadData_UploadContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 10px;
    height: 100%;
    width: 90%;
    position: relative;
    margin: auto;
    align-items: center;
    top: 1%;
  }
  .uploadFileContainer {
    display: flex;
    flex-direction: column;
    height: 80%;
    width: 100%;
    position: relative;
    align-items: center;
  }
  /* .uploadFileContainer > h3 {
        color: black;
        font-weight: bold;
        text-align: center;
    } */
  .parentContent {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 14rem;
  }
  .textUpload {
    display: none;
  }
  .parentContent > button {
    cursor: pointer;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 70px;
    border: none;
    color: white;
    font-size: 21px;
    font-weight: bold !important;
    background: #000129;
  }
}
