
.zebrafishImg {
    max-height: 92%;
    max-width: 92%;
}
@media screen and (min-width: 750px) {
    .heatmap {
        display: grid;
        justify-content: center;
        overflow-y: hidden;
        overflow-x: scroll;
        max-width: 95%;
        height: 92%;
    }
    .zebrafish {
        height: 92%;
    }

}


@media screen and (max-width: 750px) {
    .heatmap {
        justify-content: center;
        overflow-y: hidden;
        overflow-x: scroll;
        max-width: 92%;
        height: 92%;
        
    }
    .zebrafish {
        height: 92%;
    }

}
