.selected-data-shown-container {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  

}
 
.filter-item {
  margin-left: 10px;
  color: white;
margin-top: 10px;
margin-bottom: 10px;
  
}

.filter-icon {
  margin-left: 10px;
  border-radius: 7px; /* Fixed missing 'px' unit */
  background-color: #D9A43A !important;
  color: white;
  margin-top: 5px;

  margin-bottom: 10px;

}

.date-chip {
  margin-left: 10px;
  border-radius: 7px; /* Fixed missing 'px' unit */
  background-color: #8B9A46 !important;
  color: white;
  margin-top: 5px;
  margin-bottom: 10px;

}

.iconEdit-container{
  display: flex;
  justify-content: flex-end;
  

}
.iconEdit {
  color: rgba(231, 222, 222, 0.926);
  cursor: pointer;
  transition: color 0.3s;
  margin-bottom: 5px;
}

.iconEdit:hover {
  color: white;
}

.button-save{


  background-color:#d60b51 !important; /* Set the background color to gray */

  color: white !important;

}
.save-cancel-buttons{

  display: flex;
  justify-content: flex-end;

}