.container {
  box-shadow: 0 4px 8px rgba(128, 128, 128, 0.5);
  padding: 10px;

  background-color: rgb(34 38 65);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .container {
    align-items: center;
  }
}

@media (min-width: 769px) {
  .container {
    margin-left: 20px; /* You can adjust the margin value to your preference */
    margin-right: 20px;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.inline-flex {
  display: flex;
  align-items: center;
  margin-bottom: -3px;
  margin-top: -3px !important;
}

div .text1 {
  margin-right: 10px;
  font-weight: bold;
  font-family: inherit;
  text-decoration: none !important;
  color: white;
  font-size: 16px;
}

.change {
  font-weight: normal;
  font-family: inherit;
}

.icon {
  margin-right: 5px;
  vertical-align: middle;
}

.space {
  margin-right: 3px;
  font-size: 17px;
}
.gray-text {
  color: white;
  font-size: 16px;
}

.bold-text {
  color: white;
}
.small-space {
  margin: 0 5px;
}

.new-line {
  margin-top: 7px;
  font-size: 17px;
}
.new-line-stat {
  margin-top: 7px;
  font-size: 17px;
  font-weight: bold;
}

.change-button {
  font-weight: bold;
  font-family: inherit;
  text-decoration: none !important;
  color: #ffffff !important; /* Set the text color to white */
  background-color: rgb(
    214,
    11,
    81
  ) !important; /* Set the background color to pink */
  border: none !important; /* Remove the border */
  cursor: pointer;
  text-transform: lowercase !important; /* Convert text to lowercase */
}

.change-button:hover {
  background-color: #ff4d99 !important; /* Change background color on hover */
}
.grey-icon {
  color: grey !important;
}
