 
  .filtrecontainer{
    box-shadow: 0 4px 8px rgba(128, 128, 128, 0.5);  
    padding: 10px;
   
    background-color: rgb(34 38 65);
  }
 

  @media (min-width: 769px) {
    .filtrecontainer {
     margin: 20px; 
    }
  
  
  }
.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Optional: Center vertically */
  border-bottom: 2px solid #D9A43A !important; 

}

.titletexte {
    margin-right: 10px;
    margin-bottom: 5px !important;
    font-weight: 3px !important; /* Change font weight to normal */
    font-size: 15px !important; /* Change font size to your preference */
    font-family: inherit;
    text-decoration: none !important;
    color: white;
  }

 
 .addfilter{

display: 'flex';
 align-items: 'center';
 }
.addfilter-button{
  color: white;
  text-transform: capitalize !important; /* This will make the text appear in title case */

}

.save-cancel-buttons{

  display: flex;
  justify-content: flex-end;

}
.button-cancel{

  margin-right: 10px !important;
  color: white !important;
  background-color: rgb(171, 169, 169) !important; /* Set the background color to gray */

}

.button-save{


  background-color:#d60b51 !important; /* Set the background color to gray */

  color: white !important;

}