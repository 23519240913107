.Tablecontainer{
    box-shadow: 0 4px 8px rgba(128, 128, 128, 0.5);  
    padding: 10px;
   
    background-color: rgb(34 38 65);
  }
  
  @media (min-width: 769px) {
    .Tablecontainer {
     margin: 20px; 
    }
  }
  
  
  .titletext {
      margin-right: 10px;
      margin-bottom: 5px !important;
      font-weight: 3px !important; /* Change font weight to normal */
      font-size: 15px !important; /* Change font size to your preference */
      font-family: inherit;
      text-decoration: none !important;
      color: white;
    }
    
  .normaltext {
  color: white;
  font-weight: 2px;
  margin-bottom: 8px !important;
  padding-left: 13px !important;
  }
  .closeIcon {
    cursor: pointer;
    float: right;
    color: #000; /* or any color you prefer */
  }
  
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flex-space-betweena {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Optional: Center vertically */
    border-bottom: 2px solid #D9A43A !important; 

    
  
  }

  