

  .selected-data-shown-agg {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    
  
  }
  .date-chip {
    margin-left: 10px;
    border-radius: 7px; /* Fixed missing 'px' unit */
    background-color: #8B9A46 !important;
    color: white;
    margin-top: 5px;
    margin-bottom: 10px;
  
  }

  
  .filter-item {
    margin-left: 10px;
    color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  }  
  .filter-icon {
    margin-left: 10px;
    border-radius: 7px; /* Fixed missing 'px' unit */
    background-color: #D9A43A !important;
    color: white;
    margin-top: 5px;
  
    margin-bottom: 10px;
  
  }