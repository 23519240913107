body {
  background-color: rgb(2, 8, 52);
  margin: 0;
}

.centered-container {
  display: flex;
  margin-left: 5%;
  align-items: center;
  height: 60vh;
}

.data-ingestion-container {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 80%; /* Use a relative width for responsiveness */
  max-width: 80%; /* Set a maximum width to avoid excessive expansion */
  text-align: center;
}

.steps-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.step {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(150, 150, 150);
  font-weight: bold;
  cursor: pointer;
  position: relative;
}

.step.active {
  color: rgb(255, 255, 255);
}

.step-number {
  width: 40px; /* Larger width */
  height: 40px; /* Larger height */
  background-color: rgb(2, 8, 52);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  color: rgb(255, 255, 255);
  margin-bottom: 10px;
}
.pink {
  width: 40px; /* Larger width */
  height: 40px; /* Larger height */
  background-color: rgb(214, 11, 81);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  color: rgb(0, 0, 0);
  margin-bottom: 10px;
}

.step-line {
  width: 2px;
  height: 100%;
  background-color: rgb(200, 200, 200);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
  z-index: -1;
}
.content-step-text {
  width: 170px;
  height: 50px;
  background-color: rgb(16, 36, 76);
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-step-text:hover {
  background: rgb(214, 11, 81);
}

.cursor-pointer {
  cursor: pointer;
}
