.containerColumn {
  padding: 0 10px;
  border-bottom: 1px solid #d9a43a !important;
  margin-top: 20px;
}
.columnflex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px; /* Add the gap property to specify the gap between flex items */
}

@media screen and (max-width: 768px) {
  .containerColumn {
    padding: 0 5px;
  }
  .columnflex {
    /* Add your phone-specific styles here */
    gap: 5px;
    /* You can include additional styles or overrides specific to phone screens. */
  }

  .filter-title {
    font-size: 8px;
    padding-left: 0;
  }
}
.filter-title {
  font-size: 15px;
  color: #ffffff;
  margin-top: -20px;
}
.icondelete-container {
  display: flex;
  justify-content: flex-end;
}

.icondelete {
  color: rgba(231, 222, 222, 0.926);
  cursor: pointer;
  transition: color 0.3s;
  margin-bottom: 5px;
}

.icondelete:hover {
  color: white;
}

.number {
  width: 470px;
  margin-top: -10px;
}
.date {
  width: 470px;
}
.text {
  margin-top: -10px;
}
@media screen and (max-width: 900px) {
  .text {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }
}
