 

/* SelectSmall.css */
.selectsmall {
    background-color: white !important;
  }
  
  .icon-selectsmall {
    color: black !important;
    background-color: white;
  }
  
  /* Add a class for ellipsis text */
  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .selectsmall .MuiSelect-root {
    background-color: #F2F3F4;
    border-radius: 5px;
    height: 40px;
    
    /* Add other default styles here */
  }
  
  /* Apply custom styles for screens wider than or equal to 768px */
  @media (min-width: 768px) {
    .selectsmall .MuiSelect-root {
      width: 500px !important;
      /* Add other custom styles for larger screens here */
    }
  }
  
  /* Apply custom styles for screens narrower than 768px (e.g., mobile screens) */
  @media (max-width: 767px) {
    .selectsmall .MuiSelect-root {
      width: 120px !important;
      /* Add other custom styles for smaller screens here */
    }
  }
  