.containerDate {
    background-color: #f2f3f4;
    margin-top: -15px;
    border-radius: 5px !important; 
    width: 300px;    
  }
  .containerDate > div > div {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);    
  }
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    height: 0.5375em !important;
}

/* Media query for screens with a maximum width of, for example, 768px */
@media screen and (max-width: 900px) {
    .MuiInputBase-input.MuiOutlinedInput-input.Mui-readOnly.MuiInputBase-readOnly.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
            /* Add your mobile-specific styles here */
        height: 0.4375em !important;
        /* You can adjust the height or include other styles specific to smaller screens. */
    }
}
 
/* Increased specificity example */
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #c4c4c4 !important;
}
/* InputDate.css */

/* Override MUI styles for the TextField within the DateTimePicker */
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #c4c4c4; /* Use the color you prefer */
}

/* Override hover effect */
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #c4c4c4; /* Use the color you prefer */
}
