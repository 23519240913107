.organ {
  display: flex;
  border: 1px solid white;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  margin: calc(-100px / 2);
  cursor: pointer;

  &__text {
    position: absolute;
    width: 100%;
    overflow: visible;
    white-space: nowrap;

    &--right {
      right: -120%;
    }

    &--top {
      text-align: center;
    }

    &--left {
      width: max-content;
      text-align: right;
    }

    &--bottom {
      bottom: -60%;
      text-align: center;
      width: max-content;

      .organ__text-subtitle {
        white-space: pre-line;
      }
    }

    &-title {
      font-size: 24px;
    }

    &-subtitle {
      font-size: 16px;
    }
  }

  &__img {
    object-fit: contain;
    width: 50%;
    height: 50%;
  }
}
